import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import { FormattedMessage, Link } from "gatsby-plugin-react-intl"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const mailtoFnInfo = e => {
    e.preventDefault()
    window.location.href =
      "mailto:info@buildsight.se?subject=Kontakta%20mig&body=Kontakta%20mig%20på: "
  }
  const mailtoFn = e => {
    e.preventDefault()
    window.location.href =
      "mailto:info@buildsight.se&subject=Kontakta%20mig&body=Kontakta%20mig%20på: "
  }

  return (
    <FooterWrapper id="footer">
      <FooterColumnContainer>
        <FooterColumn>
          <ImageWrapper>
            <StyledImage fixed={data.file.childImageSharp.fixed} />
            <br />
          </ImageWrapper>
          <FormattedMessage
            id="footer_built_in"
            defaultMessage="Built in San Francisco and Stockholm with love."
          />
        </FooterColumn>
        <FooterColumn>
          <span>
            <FormattedMessage
              id="footer_resources"
              defaultMessage="Resources"
            />
          </span>
          <ul>
            <li>
              <FooterLink to="/terms-and-conditions">
                <FormattedMessage
                  id="footer_terms_and_conditions"
                  defaultMessage="Terms and Conditions"
                />
              </FooterLink>
            </li>
            <li>
              <FooterLink to="/privacy-policy">
                <FormattedMessage
                  id="footer_cookies_and_privacy"
                  defaultMessage="Privacy policy and cookies"
                />
              </FooterLink>
            </li>
            {/* <li>
            <FooterLink to='/blog'>
              <FormattedMessage id="footer_blog" defaultMessage="Blog" />
            </FooterLink>
          </li> */}
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>
            <FormattedMessage id="footer_company" defaultMessage="Company" />
          </span>
          <ul>
            <li>
              <FooterButton
                onClick={e => {
                  e.preventDefault()
                  window.open("https://calendly.com/buildsight/15min")
                }}
              >
                <FormattedMessage id="footer_demo" defaultMessage="Book demo" />
              </FooterButton>
            </li>
            <li onClick={mailtoFnInfo} onKeyDown={mailtoFnInfo}>
              <FormattedMessage
                id="footer_contact_us"
                defaultMessage="Contact us"
              />
            </li>
            <li onClick={mailtoFn} onKeyDown={mailtoFn}>
              <FormattedMessage
                id="footer_contact_us_email"
                defaultMessage="info@buildsight.se"
              />
            </li>
            <li>
              <FormattedMessage
                id="footer_contact_us_phone"
                defaultMessage="072-161 57 67"
              />
            </li>

            {/*<li>
            <FormattedMessage id="footer_about_us" defaultMessage="About Us" />
          </li>
          <li>
            <FormattedMessage id="footer_team" defaultMessage="Team" />
          </li>*/}

            {/*<li>
            <FormattedMessage id="footer_careers" defaultMessage="Careers" />
          </li>*/}
          </ul>
        </FooterColumn>
        <FooterColumn>
          <span>
            <FormattedMessage
              id="footer_social_media"
              defaultMessage="Social"
            />
          </span>
          <ul>
            <li>
              <ExternalLink href="https://www.linkedin.com/company/buildsight">
                LinkedIn
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="https://twitter.com/BuildsightApp">
                Twitter
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href="https://www.youtube.com/channel/UCB7OgAYgHWBJW9jKzqsVO6Q">
                YouTube
              </ExternalLink>
            </li>
          </ul>
        </FooterColumn>
        <span>Copyright {new Date().getFullYear()} Simplified Software AB</span>
      </FooterColumnContainer>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`

const ImageWrapper = styled.div`
  padding-bottom: 20px;
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-weight: 600;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      cursor: pointer;
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 12px;
  color: ${props => props.theme.color.black.regular};
  font-family: ${props => props.theme.font.normal};
  font-size: 15px;
`

const ExternalLink = styled.a`
  text-decoration: none;
  margin-bottom: 12px;
  color: ${props => props.theme.color.black.regular};
  font-family: ${props => props.theme.font.normal};
  font-size: 15px;
`

const FooterButton = styled.button`
  font-family: ${props => props.theme.font.normal};
  ${props => props.theme.font_size.xsmall};
  color: white;
  border-radius: 10px;
  padding: 8px 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: white;
  letter-spacing: 1px;
  height: 30px;
  display: block;
  margin-left: 0px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default Footer
